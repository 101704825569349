import { LoadingButton, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Skeleton, Stack, ThemeProvider } from "@mui/material";
import { type Worker } from "@src/appV2/Worker/api/types";
import { isEqual } from "lodash";
import { useState } from "react";

import { APP_V2_APP_EVENTS } from "../lib";
import { logEvent } from "../lib/analytics";
import { PullToRefresh } from "../lib/PullToRefresh/PullToRefresh";
import { useDebounce } from "../lib/utils/useDebounce";
import { PlacementCandidateProfileUpdateDialog } from "../PlacementCandidate/components/PlacementCandidateProfileUpdateDialog";
import { type PlacementCandidate, PlacementCandidateStatus } from "../PlacementCandidate/types";
import { Callout } from "../ShiftDiscovery/components/Callout";
import { getShiftDiscoveryTheme } from "../ShiftDiscovery/theming/theme";
import {
  transformResponseIntoPlacement,
  useFetchPaginatedPlacements,
} from "./api/useFetchPaginatedPlacements";
import { DEFAULT_DISTANCE_FILTER_IN_MILES, FilterButton } from "./components/FilterButton";
import { PlacementCardItem } from "./components/PlacementCardItem";
import { SearchField } from "./components/SearchField";
import { type GetPlacementsForWorkplaceQuery } from "./types/fetchPlacements.schema";

interface PlacementsPageProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function PlacementsPage(props: PlacementsPageProps) {
  const { placementCandidate, worker } = props;
  const updateCandidateProfileModalState = useModalState();
  const defaultFilter = {
    distanceInMiles:
      placementCandidate.preferredAreas?.[0]?.distance ?? DEFAULT_DISTANCE_FILTER_IN_MILES,
    workerTypes: worker.licensesData?.map((license) => license.qualification).join(","),
  };
  const [filter, setFilter] = useState<GetPlacementsForWorkplaceQuery["filter"]>(defaultFilter);
  const [searchByWorkplaceName, setSearchByWorkplaceName] = useState<string>("");
  const debouncedSearchByWorkplaceName = useDebounce({
    value: searchByWorkplaceName,
    debounceTimeInMs: 1000,
    onUpdateValue: (value) => {
      setSearchByWorkplaceName(value);
    },
  });

  const {
    data: placementsData,
    isLoading: isPlacementsLoading,
    isSuccess: isPlacementsSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchPlacements,
  } = useFetchPaginatedPlacements(
    {
      workerId: worker.userId,
      placementCandidateId: placementCandidate.id,
      filter: filter
        ? {
            ...filter,
            workplaceName: debouncedSearchByWorkplaceName ?? undefined,
          }
        : undefined,
      defaultFilter,
    },
    {
      enabled:
        isDefined(worker.userId) &&
        placementCandidate.status === PlacementCandidateStatus.ACTIVATED,
    }
  );
  const nonEmptyFilter = Object.fromEntries(
    Object.entries(filter ?? {}).filter(([_, value]) => value)
  );

  return (
    <>
      <ThemeProvider theme={getShiftDiscoveryTheme()}>
        <PullToRefresh onRefresh={refetchPlacements} />

        <Stack
          sx={{
            overflowY: "auto",
            paddingTop: 5,
            paddingX: 5,
            paddingBottom: 12,
            backgroundColor: (theme) => theme.palette.background.primary,
          }}
          flex={1}
          spacing={2}
        >
          <Box sx={{ paddingBottom: 5 }}>
            <Callout
              title="Update profile"
              text="View or edit your job profile"
              iconType="passkeys"
              sx={(theme) => ({
                backgroundColor: theme.palette.shifts?.general.background,
              })}
              onClick={() => {
                logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_PROFILE_UPDATE_CLICKED);
                updateCandidateProfileModalState.openModal();
              }}
            />
          </Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Text semibold gutterBottom variant="h4">
              Open Jobs
            </Text>
          </Stack>
          <Box>
            <SearchField value={searchByWorkplaceName} onChange={setSearchByWorkplaceName} />

            <Stack
              direction="row"
              justifyContent="flex-start"
              sx={{
                minHeight: "1.5rem",
              }}
            >
              {!isEqual(nonEmptyFilter, defaultFilter) && (
                <Button
                  size="small"
                  variant="text"
                  sx={{
                    paddingX: 2,
                    textDecoration: "underline",
                    textDecorationColor: (theme) => theme.palette.border?.primary,
                    textUnderlineOffset: 6,
                  }}
                  onClick={() => {
                    setFilter(defaultFilter);
                    logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
                      placementCandidateId: placementCandidate.id,
                      action: "filters_cleared",
                      filters: { ...filter, workplaceName: debouncedSearchByWorkplaceName },
                    });
                  }}
                >
                  Clear filters to view all jobs
                </Button>
              )}
            </Stack>
          </Box>

          {isPlacementsLoading && (
            <Stack direction="column" spacing={2}>
              {Array.from({ length: 3 }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Skeleton key={index} variant="rounded" width="100%" height={100} />
              ))}
            </Stack>
          )}

          {isPlacementsSuccess && (placementsData.pages[0]?.data.length ?? 0) === 0 && (
            <Stack justifyContent="center" flex={1} alignItems="center">
              <Text variant="body1">No relevant jobs listed yet!</Text>
            </Stack>
          )}

          {isDefined(placementsData) && placementsData.pages.length > 0 && (
            <Stack direction="column" spacing={6}>
              {placementsData?.pages.flatMap((placementPage, pageIndex) => {
                return placementPage.data.map((placement, placementIndex) => {
                  const placementWithDetails = transformResponseIntoPlacement(
                    placement,
                    placementPage.included
                  );
                  return (
                    <PlacementCardItem
                      key={placement.id}
                      placement={placementWithDetails}
                      placementCandidateId={placementCandidate.id}
                      pageIndex={pageIndex}
                      placementIndex={placementIndex}
                    />
                  );
                });
              })}
            </Stack>
          )}

          {hasNextPage && (
            <Stack direction="row" justifyContent="center">
              <LoadingButton
                sx={{ mb: 10 }}
                isLoading={isFetchingNextPage}
                onClick={async () => {
                  await fetchNextPage();
                  logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
                    placementCandidateId: placementCandidate.id,
                    filters: { ...filter, workplaceName: debouncedSearchByWorkplaceName },
                    action: "load_more_clicked",
                  });
                }}
              >
                Load more
              </LoadingButton>
            </Stack>
          )}
        </Stack>
        <FilterButton
          filter={filter}
          setFilter={setFilter}
          worker={worker}
          placementCandidateId={placementCandidate.id}
          searchByWorkplaceName={debouncedSearchByWorkplaceName}
        />
      </ThemeProvider>
      {updateCandidateProfileModalState.modalIsOpen && (
        <PlacementCandidateProfileUpdateDialog
          updateCandidateProfileModalState={updateCandidateProfileModalState}
          placementCandidate={placementCandidate}
          worker={worker}
        />
      )}
    </>
  );
}
